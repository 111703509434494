<template>
  <div>
    <el-dialog
      title="订单详情"
      :visible.sync="isyunshowdialog"
      width="60%"
      center
    >
      <el-row :gutter="24" style="margin-bottom: 15px;">
        <el-col :span="6" class="cardstyle">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>市场信息</span>
            </div>
            <div class="text item fltes">市场：{{ ruleForm.market_name }}</div>
            <div class="text item fltes">商户：{{ ruleForm.seller_id }}</div>
            <div class="text item fltes">秤编号：{{ ruleForm.device_id }}</div>
          </el-card>
        </el-col>
        <el-col :span="6" class="cardstyle">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>订单信息</span>
            </div>
            <div class="text item fltes">订单编号：{{ ruleForm.order_id }}</div>
            <div class="text item fltes">订单时间：{{ ruleForm.date }}</div>
            <div class="text item fltes">
              支付类型：
              <span v-if="ruleForm.pay_type == 1">微信</span>
              <span v-if="ruleForm.pay_type == 2">支付宝</span>
              <span v-if="ruleForm.pay_type == 3">现金</span>
            </div>
            <div class="text item fltes">
              支付时间：{{ ruleForm.created_at }}
            </div>
          </el-card>
        </el-col>
        <el-col :span="6" class="cardstyle">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>支付信息</span>
            </div>
            <div class="text item fltes">订单金额：{{ ruleForm.total }}</div>
            <div class="text item fltes">
              交易金额：{{ ruleForm.pay_price }}
            </div>
          </el-card>
        </el-col>
        <el-col :span="6" class="cardstyle">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>订单状态</span>
            </div>
            <div class="text item fltes">
              订单状态：{{ ruleForm.status | goodsType }}
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>商品信息</span>
        </div>
        <el-table :data="ruleForm.orders_list" style="width: 100%">
          <el-table-column prop="product_name" label="商品" width="180">
          </el-table-column>
          <el-table-column prop="price" label="单价" width="180">
          </el-table-column>
          <el-table-column prop="amount" label="商品数量"> </el-table-column>
          <el-table-column prop="total_price" label="金额" width="180">
          </el-table-column>
          <el-table-column prop="unit" label="单位" width="180">
          </el-table-column>
          <el-table-column prop="address" label="商品图片">
            <template slot-scope="scope">
              <img :src="scope.row.data_image" alt="" style="width:50px" />
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-dialog>
  </div>
</template>
<script>
import { getorder } from '../../../api/electronicList'

export default {
  data () {
    return {
      isyunshowdialog: false,
      ruleForm: {}
    }
  },
  methods: {
    get_sellerList (num) {
      getorder(num).then(res => {
        if (res.code === 200) {
          this. ruleForm={}
          this.ruleForm = res.data


          console.log(this.ruleForm)
        }
      })
    }
  },
  filters: {
    goodsType (status) {
      let result = ''
      switch (status) {
        case 0:
          result = '待支付'
          break
        case 1:
          result = '支付成功'
          break
        case 2:
          result = '支付失败'
          break
      }
      return result
    }
  }

  // pay_type
  // :0=待支付,1=支付成功,2=支付失败
}
</script>
<style scoped>
.title1 {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.width {
  display: inline-block;
  width: 80px;
  text-align: left;
  margin-right: 20px;
  flex-shrink: 0;
}

.upload {
  display: flex;
}

.visible {
  margin-top: 20px;
}

.uploadItem {
  text-align: center;
  margin: 0 10px;
}

.uploadItem1 {
  text-align: center;
  margin-right: 10px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.between {
  display: flex;
  justify-content: space-between;
}

.margin /deep/.el-form-item__content {
  margin-left: 0 !important;
}
.cardstyle /deep/ .el-card__body {
  height: 100px;
}
.fltes {
  padding-bottom: 10px;
}
</style>
