<template>
  <div>
    <el-row>
      <!--      <el-col :span="5" style="margin-right: 15px">-->
      <!--        <el-date-picker-->
      <!--          clearable-->
      <!--          v-model="value1"-->
      <!--          type="daterange"-->
      <!--          range-separator="至"-->
      <!--          start-placeholder="开始日期"-->
      <!--          end-placeholder="结束日期">-->

      <!--        </el-date-picker>-->
      <!--      </el-col>-->
      <el-col :span="3" style="margin-right: 15px">
        <el-select
          v-model="formList.market_id"
          placeholder="请选择市场"
          clearable
        >
          <el-option
            clearable
            v-for="item in data2.options1"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"
            @click.native="ByMarketId"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="margin-right: 15px">
        <el-select
          v-model="formList.seller_id"
          placeholder="请选择商户"
          clearable
        >
          <el-option
            clearable
            v-for="item in data2.options2"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="margin-right: 15px">
        <el-select
          v-model="formList.status"
          placeholder="请选择订单状态"
          clearable
        >
          <el-option
            clearable
            v-for="item in stuteslist"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="margin-right: 15px">
        <el-select
          v-model="formList.pay_type"
          placeholder="请选择付款方式"
          clearable
        >
          <el-option
            clearable
            v-for="item in play_type"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <!--      <el-col :span="3" style="margin-right: 15px">-->
      <!--        <el-select v-model="value" placeholder="请选择订单类型" clearable >-->
      <!--          <el-option-->
      <!--            clearable-->
      <!--            v-for="item in play_type"-->
      <!--            :key="item.id"-->
      <!--            :label="item.value"-->
      <!--            :value="item.id"-->
      <!--          >-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </el-col>-->
      <el-col :span="3" style="margin-right: 15px;">
        <el-input
          clearable
          v-model="formList.device_id"
          style="width:217px"
          placeholder="请输入电子秤编号"
        ></el-input>
      </el-col>

      <!--      <el-col :span="3" style="margin-right: 15px">-->
      <!--        <el-input-->
      <!--          clearable-->
      <!--          v-model="formList.order_code"-->
      <!--          style="width:217px"-->
      <!--          placeholder="请输入订单号"-->
      <!--        ></el-input>-->
      <!--      </el-col>-->
      <el-col :span="3" style="margin-right: 15px">
        <el-input
          clearable
          v-model="formList.serial_num"
          style="width:217px"
          placeholder="请输入流水号"
        ></el-input>
      </el-col>
      <!--      <el-col :span="3" style="margin-right: 15px;margin-top: 20px">-->
      <!--        <el-input-->
      <!--          clearable-->
      <!--          v-model="formList.order_code"-->
      <!--          style="width:217px"-->
      <!--          placeholder="请输入商品名称"-->
      <!--        ></el-input>-->
      <!--      </el-col>-->
      <!--      <el-col :span="3" style="margin-right: 15px;margin-top: 20px">-->
      <!--        <el-input-->
      <!--          clearable-->
      <!--          v-model="formList.operator"-->
      <!--          style="width:217px"-->
      <!--          placeholder="请输入操作人"-->
      <!--        ></el-input>-->
      <!--      </el-col>-->
      <el-col :span="3" style="margin-right: 15px;">
        <el-button type="primary" @click="get_orders">查询</el-button>
        <!--        <el-button>导出数据</el-button>-->
        <!--        <el-button>导出订单详情</el-button>-->
        <!--        <el-button>隐藏>></el-button>-->
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center' }"
      stripe
      border
      style="width: 100%;margin-top:20px"
    >
      <el-table-column prop="serial_num" label="流水号" width="180">
      </el-table-column>
      <!-- <el-table-column prop="veg_name" label="商品名称" width="180">
      </el-table-column> -->
      <el-table-column prop="pay_price" label="交易金额（元）">
      </el-table-column>
      <el-table-column prop="pay_type" label="付款方式">
        <template slot-scope="scope">
          <span v-if="scope.row.pay_type == 1">微信</span>
          <span v-if="scope.row.pay_type == 2">支付宝</span>
          <span v-if="scope.row.pay_type == 3">现金</span>
        </template>
      </el-table-column>
      <el-table-column prop="order_type" label="订单类型">
        <template slot-scope="scope">
          <span v-if="scope.row.order_type == 1">过秤</span>
          <span v-if="scope.row.order_type == 2">线上</span>
        </template>
      </el-table-column>
      <el-table-column prop="device_id" label="电子秤编号"> </el-table-column>
      <el-table-column prop="seller_name" label="商户"> </el-table-column>
      <el-table-column prop="market_name" label="市场"> </el-table-column>
      <el-table-column label="订单状态">
        <template slot-scope="scope">
          {{ scope.row.status | goodsType }}
        </template>
      </el-table-column>

      <el-table-column prop="date" label="交易时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <addMaintain ref="refmaintain"></addMaintain>
  </div>
</template>

<script>
import { getmarket } from '../../../api/layout'
import { orders } from '../../../api/orderFormList'

import addMaintain from '../orderFormList/detailsFormLisr'
import { getSellerByMarketId } from '../../../api/productList'
export default {
  name: 'orderFormList',
  components: {
    addMaintain
    // detailsMaintain
  },
  data () {
    return {
      options: '',
      value1: '',
      // 第二行的数据
      data2: {
        options1: '',
        options2: '',
        options3: '',
        options4: ''
      },
      // 第二行收集到的数据ID

      play_type: [
        { key: 1, value: '微信' },
        { key: 2, value: '支付宝' },
        { key: 3, value: '现金' }
      ],
      stuteslist: [
        { key: 0, value: '待支付' },
        { key: 1, value: '支付成功' },
        { key: 2, value: '支付失败' }
      ],
      value: '',
      input: '',
      tableData: [], // 表格数据
      total: 0,
      formList: {
        page: 1,
        limit: 10,
        market_id: '', // 市场id
        seller_id: '', // 商户类型
        pay_type: '', // 支付类型
        status: '', // 订单状态
        device_id: '', // 设备id
        order_code: '', // 订单编号
        serial_num: '', // 流水号
        operator: '' // 操作人
      }
    }
  },
  methods: {
    handleEdit (num) {
      this.$refs.refmaintain.isyunshowdialog = true
      this.$refs.refmaintain.get_sellerList(num.id)
    },

    //  市场
    get_market () {
      getmarket().then(res => {
        if (res.code === 200) {
          this.data2.options1 = res.data
        }
      })
    },
    ByMarketId () {
      this.formList.seller_id = ''
      this.formList.shop_id = ''
      getSellerByMarketId(this.formList.market_id).then(res => {
        // this.Form.sellerid = res.data
        this.data2.options2 = res.data
      })
    },
    // 表格数据
    get_orders () {
      orders(this.formList).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.formList.limit = val
      this.get_orders()
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.formList.page = val
      this.get_orders()
    }
  },
  filters: {
    goodsType (status) {
      let result = ''
      switch (status) {
        case 0:
          result = '待支付'
          break
        case 1:
          result = '支付成功'
          break
        case 2:
          result = '支付失败'
          break
      }
      return result
    }
  },

  // pay_type
  // :0=待支付,1=支付成功,2=支付失败

  mounted () {
    this.get_market()
    this.get_orders()
  }
}
</script>

<style lang="less" scoped>
.title1 {
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.title2,
.title3 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 95%;
}
.titleName {
  display: inline-block;
  width: 80px;
}
.footers {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
