import request from './request'

// 获取交易列表
export const orders = (params) => {
  return request({
    url: 'admin/v1/orders',
    method: 'GET',
    params
  })
}
